/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listProductBrand = params => axios({
    url: '/api/product/system/productBrand/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addProductBrand = params => axios({
    url: '/api/product/system/productBrand/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editProductBrand = params => axios({
    url: '/api/product/system/productBrand/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delProductBrand = params => axios({
    url:'/api/product/system/productBrand/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdProductBrand = params => axios({
    url: '/api/product/system/productBrand/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})
