<template>
    <div class="peditmodal">
        <a-modal :title="handle === 'add' ? '新增供应商' : '编辑供应商'" style="top: 8px;" :width="1000" v-model="visible" @ok="toSubmit" okText="提交" :maskClosable="false">
            <a-form-model ref="form" :model="rowData" :rules="formRule" layout="horizontal" :label-col="{span:3}" :wrapper-col="{span:21}">
                <a-form-model-item label="供应商名称" prop="(chName">
                    <a-input style="width: 30%" v-model="rowData.chName" placeholder="请输入供应商名称"></a-input>
                </a-form-model-item>
                <a-form-model-item label="供应商地址" prop="(sort">
                    <a-cascader style="width: 30%" :options="options" v-decorator="['addrType', {rules: [{ required: false, message: '请选择地址' }]}]" />
                </a-form-model-item>
                <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="" style="margin-left: 120px">
                    <a-input style="width: 30%" placeholder="详细地址" v-decorator="['address', {rules: [{ required: false, message: '请输入详细地址' }]}]"></a-input>
                </a-form-item>
                <a-form-model-item label="联系人" prop="(enName">
                    <a-input style="width: 30%" v-model="rowData.enName" placeholder="请输入联系人姓名"></a-input>
                </a-form-model-item>
                <a-form-model-item label="联系电话" prop="(logo">
                    <a-input style="width: 30%" v-model="rowData.logo" placeholder="请输入联系方式"></a-input>
                </a-form-model-item>
                <a-form-model-item label="状态" prop="(url">
                    <a-switch checked-children="启用" un-checked-children="关闭" default-checked />
                </a-form-model-item>
                <a-form-model-item label="是否启用" prop="(flagEnable">
                    <a-input style="width: 30%" v-model="rowData.flagEnable" placeholder="是否启用"></a-input>
                </a-form-model-item>
                <a-form-model-item label="上传附件" prop="(story">
                    <a-upload
                        name="file"
                        :multiple="true"
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        :headers="headers"
                        @change="handleChange" >
                        <a-button> <a-icon type="upload" />上传文件</a-button>
                        <div class="upload-file-text">支持扩展名：.doc .docx .pdf .jpg .png...</div>
                    </a-upload>
                </a-form-model-item>
                <a-form-model-item label="上传图片" prop="(createUser">
                    <div class="clearfix">
                        <a-upload
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            list-type="picture-card"
                            :file-list="ImgList"
                            @preview="handleImgPreview"
                            @change="handleImgChange"
                        >
                        <div v-if="fileList.length < 8">
                            <a-icon type="plus" />
                            <div class="ant-upload-text">上传</div>
                        </div>
                        </a-upload>
                        <a-modal :visible="previewVisible" :footer="null" @cancel="handleImgCancel">
                            <img alt="example" style="width: 100%" :src="previewImage" />
                        </a-modal>
                        <div class="upload-file-text">支持多张图片上传，支持扩展名：.jpg .png...</div>
                    </div>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
    
</template>

<script>
import { editProductBrand, selectByIdProductBrand, addProductBrand } from '../api/productBrandApi'
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
export default {
    data() {
        return {
            visible: false,
            handle: 'add',
            rowData: {},
            options: [],
            fileList: [],
            ImgList: [],
            // 表单验证
            formRule: {
                id: [
                    { required: true, message: '请输入', trigger: 'blur' }
                ],
                chName: [
                    { required: true, message: '请输入品牌中文名称', trigger: 'blur' }
                ],
                enName: [
                    { required: true, message: '请输入品牌英文名称', trigger: 'blur' }
                ],
                sort: [
                    { required: true, pattern:new RegExp(/^[0-9]+$/), message: '请输入排序(正整数)', trigger: 'blur' }
                ],
                logo: [
                    { required: true, message: '请输入品牌logo', trigger: 'blur' }
                ],
                url: [
                    { required: true, message: '请输入品牌官方地址', trigger: 'blur' }
                ],
                story: [
                    { required: true, message: '请输入品牌简介', trigger: 'blur' }
                ],
                flagEnable: [
                    { required: true, message: '请输入是否启用', trigger: 'blur' }
                ],
                createUser: [
                    { required: true, message: '请输入创建人', trigger: 'blur' }
                ],
                createUserId: [
                    { required: true, message: '请输入创建人id', trigger: 'blur' }
                ],
                createTime: [
                    { required: true, message: '请输入创建时间', trigger: 'blur' }
                ],
                modifyUser: [
                    { required: true, message: '请输入修改人', trigger: 'blur' }
                ],
                modifyUserId: [
                    { required: true, message: '请输入修改人id', trigger: 'blur' }
                ],
                modifyTime: [
                    { required: true, message: '请输入修改时间', trigger: 'blur' }
                ],
                flagDel: [
                    { required: true, message: '请输入是否删除', trigger: 'blur' }
                ],
                remark: [
                    { required: true, message: '请输入备注', trigger: 'blur' }
                ],
                version: [
                    { required: true, message: '请输入版本号', trigger: 'blur' }
                ],
            }
        }
    },
    methods: {
        /**
         * 获取行数据
         */
        setRowData(row, handle){
            this.handle = handle
            this.visible = true
            this.rowData = row
            console.log('this.rowData----',this.rowData)
        },
        /**
         * 表单提交
         */
        toSubmit() {
            this.$refs.form.validate(async valid => {
                if (!valid) {
                    return
                }
                const res = this.handle === 'add' ? await addProductBrand(this.rowData) : await editProductBrand(this.rowData)
                if (res.code === 200) {
                    this.$notification.success({ message: res.message })
                    this.$emit('reload')
                    this.visible = false
                    this.rowData = {}
                } else {
                    this.$notification.error({ message: res.message })
                }
            })
        },
        // 上传文件
        handleChange(info) {
            let fileList = [...info.fileList];
            // 1. Limit the number of uploaded files
            //    Only to show two recent uploaded files, and old ones will be replaced by the new
            fileList = fileList.slice(-2);

            // 2. read from response and show file link
            fileList = fileList.map(file => {
                if (file.response) {
                // Component will show file.url as link
                file.url = file.response.url;
                }
                return file;
            });

            this.fileList = fileList;
        },
        // 上传图片
        handleImgCancel() {
            this.previewVisible = false;
        },
        async handleImgPreview(file) {
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj);
            }
            this.previewImage = file.url || file.preview;
            this.previewVisible = true;
        },
        handleImgChange({ fileList }) {
            this.ImgList = fileList;
        },
    },
    created() {

    },

}
</script>

<style lang="less" scoped>
    .ant-upload-select-picture-card i {
        font-size: 32px;
        color: #999;
    }

    .ant-upload-select-picture-card .ant-upload-text {
        margin-top: 8px;
        color: #666;
    }
</style>
